import React, { Suspense } from "react";
import { SnackbarProvider } from "notistack";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, CssBaseline, Grid } from "@mui/material";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

import { Modules } from "./Components/Modules";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import { AuthContextProvider } from "./Providers/Auth";
import { BrandContext, BrandContextProvider } from "./Providers/Brand";
import { RegistryContextProvider } from "./Providers/RegistryContext";
import { LoadingIndicator } from "./Components/LoadingIndicator";

import { ApolloProvider } from "@apollo/client";
import { client } from "./Api/ApiClient";

import brandConfig from "./brand/config";

import App from "./App";

export default function AppContainer() {
  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <Suspense
      fallback={
        <Grid container justifyContent="center" alignItems="center" sx={{height:"100vh", }}>
          <LoadingIndicator />
        </Grid>
      }
    >
      <BrandContextProvider config={brandConfig}>
        <RegistryContextProvider>
          <Modules>
            {brandConfig.modules.map((Module: any, idx: number) => (
              <Module key={"module_" + idx} sortOrder={1 + idx} />
            ))}
          </Modules>
          <StyledEngineProvider injectFirst>
            <ApolloProvider client={client}>
              <BrandContext.Consumer>
                {(context) => (
                  <ThemeProvider theme={context.theme}>
                    <CssBaseline />
                    <ErrorBoundary>
                      <AuthContextProvider>
                        <SnackbarProvider
                          maxSnack={3}
                          action={(key) => (
                            <IconButton onClick={onClickDismiss(key)}>
                              <CloseIcon />
                            </IconButton>
                          )}
                          ref={notistackRef}
                        >
                          <App />
                        </SnackbarProvider>
                      </AuthContextProvider>
                    </ErrorBoundary>
                  </ThemeProvider>
                )}
              </BrandContext.Consumer>
            </ApolloProvider>
          </StyledEngineProvider>
        </RegistryContextProvider>
      </BrandContextProvider>
    </Suspense>
  );
}
