import React, { useContext, useState } from "react";

type ContextType = {
  filterValue: string;
  setFilterValue: any;
};

const MarkableContext = React.createContext<ContextType | undefined>(undefined);

const MarkableFilterProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [filterValue, setFilterValue] = useState("");
  const value = {
    filterValue,
    setFilterValue,
  };

  return (
    <MarkableContext.Provider value={value}>
      {children}
    </MarkableContext.Provider>
  );
};

const useMarkableFilter = () => {
  const context = useContext(MarkableContext);
  if (context === undefined) {
    throw new Error(
      "useMarkableFilter must be used within a MarkableFilterProvider"
    );
  }
  return context;
};

export { MarkableFilterProvider, useMarkableFilter };
