import { MarkingProductQuery as MPQuery } from "../../../Modules/Storefront/pages/ProductDetail";
import { CartItemsQuery } from "../../../Modules/ShoppingCart/ShoppingCart";

const dataSources = {
  getCartItemByID: (cache, id) => {
    return cache.find((ci) => ci.markingProductID === id);
  },
  removeCartItemById: (cartItems, id, markableID, additionalInfo) => {
    return cartItems.filter(
      (ci) =>
        ci.markingProduct.uuid !== id ||
        ci.markable !== markableID ||
        ci.additionalInfo !== additionalInfo
    );
  },
  getMarkingProductByID: async (client, id) => {
    const { data } = await client.query({
      query: MPQuery,
      variables: { uuid: id },
      fetchPolicy: "cache-first",
    });
    return data.markingProduct;
  },
};

export default {
  Mutation: {
    updateCartItem: async (_, { cartItem }, { cache, client }) => {
      let { cartItems } = cache.readQuery({ query: CartItemsQuery });
      let newItem = null;

      cartItems.forEach((item) => {
        if (
          item.markingProduct.uuid === cartItem.markingProductID &&
          item.markable === cartItem.markable &&
          item.additionalInfo === cartItem.additionalInfo
        ) {
          newItem = {
            __typename: "ShoppingCart",
            markingProduct: item.markingProduct,
            quantity: item.quantity + cartItem.quantity,
            markable: item.markable,
            additionalInfo: cartItem.additionalInfo,
          };

          cache.writeQuery({
            query: CartItemsQuery,
            data: {
              quantity: item.quantity + cartItem.quantity,
            },
          });
        }
      });

      if (!newItem) {
        const markingProduct = await dataSources.getMarkingProductByID(
          client,
          cartItem.markingProductID
        );

        if (markingProduct) {
          newItem = {
            __typename: "ShoppingCart",
            markingProduct: { ...markingProduct },
            quantity: cartItem.quantity,
            markable: cartItem.markable,
            additionalInfo: cartItem.additionalInfo,
          };

          cache.writeQuery({
            query: CartItemsQuery,
            data: { cartItems: [...cartItems, newItem] },
          });
        }
      }
      return newItem;
    },
    removeCartItem: (
      _,
      { markingProductID, markableID, additionalInfo },
      { cache }
    ) => {
      if (markingProductID && markableID) {
        const { cartItems } = cache.readQuery({ query: CartItemsQuery });
        const remainingItems = dataSources.removeCartItemById(
          cartItems,
          markingProductID,
          markableID,
          additionalInfo
        );
        cache.writeQuery({
          query: CartItemsQuery,
          data: { cartItems: [...remainingItems] },
        });
      } else {
        cache.writeQuery({
          query: CartItemsQuery,
          data: { cartItems: [] },
        });
      }
      return true;
    },
  },
  ShoppingCart: {
    markingProductID: (cart) => cart.markingProductID,
    quantity: (cart) => cart.quantity,
    markable: (cart) => cart.markable,
    additionalInfo: (cart) => cart.additionalInfo,
  },
};
