/// <reference path="../../../Types/index.d.ts" />
import React, { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@mui/material";

import { AddressItem } from "../../../Components/AddressItem";
import { PlaceOrderMutationInput as OrderInput } from "../../../gql/graphql";
import { AddressFragment } from "../../../gql/graphql";

interface AddressFormControl {
  addresses: AddressFragment[];
  onChange: (orderUpdate: Partial<OrderInput>) => void;
  name: Extract<keyof OrderInput, "deliveryAddress" | "billingAddress">; // keyof OrderInput; //Extract<"a" | "b" | "c", "a" | "f">;
  title: string;
  value: string | undefined;
  hideDefault: boolean;
}

export const AddressFormControl = (props: AddressFormControl) => {
  const { t } = useTranslation();
  const { addresses, name, title, onChange, value } = props;

  const [defaultValue, setDefaultValue] = React.useState("");

  const categoryNames = {
    deliveryAddress: "DELIVERY",
    billingAddress: "BILLING",
  };

  useEffect(() => {
    const defaultValues = addresses
      .filter((a: any) =>
        a.metas.find(
          (c: any) => c.category === categoryNames[name] && c.isPrimary
        )
      )
      .map((a: any) => a.uuid);

    if (defaultValues.length > 0) {
      setDefaultValue(defaultValues[0]);
      onChange({ [name]: defaultValues[0] });
    } else {
      // if no isPrimary is founded
      onChange({ [name]: addresses[0].uuid });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateValue = (_: ChangeEvent<{}>, value: string) => {
    onChange({ [name]: value });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {value && (
        <FormControl component="fieldset">
          <RadioGroup name={name} onChange={updateValue} defaultValue={value}>
            {addresses
              ? addresses.map((address, id: number) => {
                  return address && !address.deleted ? (
                    <FormControlLabel
                      style={{ margin: "8px" }}
                      key={id}
                      value={address.uuid.toString()}
                      control={<Radio />}
                      label={
                        <div>
                          {address.uuid === defaultValue &&
                            !props.hideDefault && (
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >{`(${t("default")})`}</Typography>
                            )}
                          <AddressItem
                            variant="body1"
                            dense={true}
                            address={address}
                          />
                        </div>
                      }
                    />
                  ) : null;
                })
              : null}
          </RadioGroup>
        </FormControl>
      )}
    </div>
  );
};
