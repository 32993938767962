import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import ContentWrapper from "../../components/ContentWrapper";
import { useTheme } from "@mui/material/styles";
import ChartSkeleton from "../../components/ChartSkeleton";
import TableSkeleton from "../../components/TableSkeleton";

import CompareChart from "./CompareChart";
import TopOrders from "./TopOrders";
// Query
import { useQuery } from "@apollo/client";
import { getGenericColor } from "../../utils";
import DashboardVolumeControl from "./DashboardVolumeControl";
import { useFlag } from "../../../../orderingFlags";
import { graphql } from "../../../../gql";

export const GetStatisticsDataQuery = graphql(/* GraphQL */ `
  query getStatisticsData {
    dashboard {
      monthlyVolume {
        month
        year
        monthName
        volume
      }
      top5Companies {
        ...Company
      }
    }
  }
`);

export const companyVolumeFragment = graphql(/* GraphQL */ `
  fragment Company on CompanyVolume {
    companyName
    volume
  }
`);

const DashboardCharts = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const hideVolume = !useFlag(["volumeControl", "enabled"]);
  const { data, error, loading } = useQuery(GetStatisticsDataQuery, {
    fetchPolicy: "no-cache",
  });

  if (error) throw error;

  return (
    <>
      <Grid container spacing={3} mb={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Order volume last 12 months")}
            bgcolor={getGenericColor(theme, 4)}
            body={
              loading ? (
                <ChartSkeleton />
              ) : (
                <CompareChart dataset={data!.dashboard!.monthlyVolume} />
              )
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentWrapper
            label={t("Top 5 accounts last 12 months")}
            bgcolor={getGenericColor(theme, 5)}
            body={
              loading ? (
                <TableSkeleton nbRows={6} rowHeight={40} />
              ) : (
                <TopOrders topCompanies={data!.dashboard!.top5Companies} />
              )
            }
          />
        </Grid>
      </Grid>
      {hideVolume ? (
        <></>
      ) : (
        <ContentWrapper
          label={t("Volumes consumed")}
          bgcolor={getGenericColor(theme, 6)}
          body={<DashboardVolumeControl />}
        />
      )}
    </>
  );
};

export default DashboardCharts;
