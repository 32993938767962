import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import {
  Grid,
  Divider,
  Typography,
  Paper,
  LinearProgress,
} from "@mui/material";
//Query
import { useQuery } from "@apollo/client";

// Components
import { Item } from "./Item";
import { ShoppingCartSubTotal } from "../../ShoppingCart/components/ShoppingCartSubTotal";
import {
  DraftOrderQuery,
  draftOrderPositionFragment,
} from "../../ShoppingCart/pages/ShoppingCartPage";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { FragmentType, getFragmentData } from "../../../gql";
import { cartItemsFragment } from "../../ShoppingCart/ShoppingCart";
import { PlaceOrderMutationInput as OrderInput } from "../../../gql/graphql";
import { markingProductFragment } from "../../Storefront/pages/ProductList";

export const OrderOverview = (props: {
  draftOrderInput: OrderInput;
  types: string[];
  cartItems: FragmentType<typeof cartItemsFragment>[];
}) => {
  const { t } = useTranslation();

  const cartItems = getFragmentData(cartItemsFragment, props.cartItems);

  const { data, loading, error } = useQuery(DraftOrderQuery, {
    variables: {
      positions: props.draftOrderInput.positions,
      deliveryAddress:
        props.types.includes(ProductType.Physical) &&
        props.draftOrderInput.deliveryAddress
          ? props.draftOrderInput.deliveryAddress
          : null,

      transportationMethod:
        props.types.includes(ProductType.Physical) &&
        props.draftOrderInput.transportationMethod
          ? props.draftOrderInput.transportationMethod
          : null,
    },
    fetchPolicy: "network-only",
  });

  if (error) throw error;

  return data && data.draftOrder ? (
    <Paper style={{ height: "100%" }}>
      <Grid container style={{ height: "inherit" }}>
        <Grid
          container
          item
          xs={12}
          style={{ height: "10%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ padding: "20px" }}
          >
            {t("Order Overview")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            height: "55%",
          }}
        >
          {data.draftOrder.orderPositions.map(
            (orderPosition, index: number) => {
              const position = getFragmentData(
                draftOrderPositionFragment,
                orderPosition
              );
              const product = getFragmentData(
                markingProductFragment,
                position.markingProduct
              );
              const cartItem = cartItems.find(
                (ci) =>
                  ci.markable === position.markable.uuid &&
                  ci.markingProduct.uuid === product.uuid &&
                  ci.quantity === position.quantity &&
                  JSON.stringify(JSON.parse(ci.additionalInfo)) ===
                  JSON.stringify(
                    JSON.parse(position.additionalInfo)
                  )
              );
              return (
                <Grid item key={index} xs={12} style={{ padding: "16px" }}>
                  <Item
                    orderPosition={orderPosition}
                    additionalInfo={cartItem?.additionalInfo}
                  />
                </Grid>
              );
            }
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid
          container
          item
          style={{ height: "35%", width: "100%" }}
          alignItems="flex-end"
        >
          <ShoppingCartSubTotal
            draftOrder={data.draftOrder}
            types={props.types}
          />
        </Grid>
      </Grid>
      {loading && <LinearProgress variant="query" />}
    </Paper>
  ) : null;
};
