import React, { Fragment } from "react";

import { ModuleRoute } from "../../Components/ModuleRoute";

import { useTranslation } from "react-i18next";

import { Imprint, Privacypolicy } from "../../Components/Footer";

import { useFlag } from "../../orderingFlags";

interface LinkModule {
  (props: Object): JSX.Element;
}

type Props = {
  sortOrder: number;
};

export const LinkModule: LinkModule = (props: any) => {
  const { t } = useTranslation();
  const { sortOrder } = props;
  const imprint = useFlag(["footers", "imprint", "markdown"]);
  const privacypolicy = useFlag(["footers", "privacypolicy", "markdown"]);

  return (
    <Fragment>
      {imprint ? (
        <ModuleRoute
          sortOrder={sortOrder}
          name={"Imprint"}
          displayName={() => t("Imprint")}
          path={"/imprint"}
          showInMenu={false}
          loginRequired={false}
          hideWhenLoggedIn={false}
          component={Imprint}
        />
      ) : null}
      {privacypolicy ? (
        <ModuleRoute
          sortOrder={sortOrder}
          name={"Privacy Policy"}
          displayName={() => t("Privacy Policy")}
          path={"/privacypolicy"}
          showInMenu={false}
          loginRequired={false}
          hideWhenLoggedIn={false}
          component={Privacypolicy}
        />
      ) : null}
    </Fragment>
  );
};
