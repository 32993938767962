import React from "react";
import { useTranslation } from "react-i18next";
import { formatValue } from "../../Utils/markingProduct";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../gql/graphql";
import { FragmentType, getFragmentData } from "../../gql/fragment-masking";
import { markingProductFragment } from "../../Modules/Storefront/pages/ProductList";
import { pricingRulesFragment } from "../../Modules/Storefront/pages/ProductList";

export const Label = (props: {
  markingProduct: FragmentType<typeof markingProductFragment>;
}) => {
  const { t } = useTranslation();
  const markingProduct = getFragmentData(
    markingProductFragment,
    props.markingProduct
  );
  const pricingRulesItems = getFragmentData(
    pricingRulesFragment,
    markingProduct.pricingRules
  );

  const scaleBasis = pricingRulesItems[0].scaleBasis;

  return (
    <>
      {markingProduct.markingProductType === ProductType.Digital
        ? ` / ${formatValue("number", scaleBasis)} ${t(" Codes")}`
        : ` / ${formatValue("number", scaleBasis)} ${t(" labels")}`}
    </>
  );
};
