import React from "react";
// Components
import { Products } from "./Products";
import { useMarkableFilter } from "../../../Providers/MarkableContext";
// Utils
import { getMPByMarkable } from "../../../Utils/markingProduct";
// Types
import { markableFragment, markingProductFragment } from "../pages/ProductList";
import { FragmentType, getFragmentData } from "../../../gql";

const DigitalProducts = (props: {
  markingProducts: FragmentType<typeof markingProductFragment>[];
  groups: FragmentType<typeof markableFragment>[];
}) => {
  const { markingProducts, groups } = props;
  const { filterValue } = useMarkableFilter();

  const markable = groups.find((obj) => {
    const markable = getFragmentData(markableFragment, obj);
    return markable.name === filterValue;
  });

  const filteredMarkingProducts = getMPByMarkable(markingProducts, filterValue);

  return (
    <>
      {filterValue && (
        <Products
          markingProducts={filteredMarkingProducts}
          markable={markable}
        ></Products>
      )}
    </>
  );
};

export default DigitalProducts;
