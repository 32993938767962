import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  HttpLink,
} from "@apollo/client";
import resolvers from "./State/resolvers";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";

import { getCookie } from "../Utils/commons";
import { CartItemsQuery } from "../Modules/ShoppingCart/ShoppingCart";

// The dataIdFromObject API is included in Apollo Client 3.0 to ease the transition from Apollo Client 2.x.
// The API might be removed in a future version of @apollo/client.
interface Identifiable {
  uuid: String;
}

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "ShoppingCart":
        const additionalInfo = responseObject.additionalInfo
          ? responseObject.additionalInfo
          : {};
        const markingProductId = responseObject.markingProduct
          ? (responseObject.markingProduct as Identifiable).uuid
          : "";

        return `ShoppingCart:${markingProductId}:${responseObject.markable}:${additionalInfo}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },

  typePolicies: {
    DashboardQuery: {
      merge: true,
    },
    OrderOverviewQuery: {
      merge: true,
    },
    RegistrationOverviewQuery: {
      merge: true,
    },
    Query: {
      fields: {
        allOrders: relayStylePagination(),
        licensingEvents: {
          keyArgs: false,
          merge(existing: any, incoming: any) {
            if (
              !incoming.pageInfo.hasNextPage &&
              !incoming.pageInfo.hasPreviousPage
            ) {
              return {
                licensingEvents: incoming.licensingEvents,
                pageInfo: incoming.pageInfo,
              };
            }
            if (
              incoming.pageInfo.hasPreviousPage &&
              incoming.pageInfo.hasNextPage
            ) {
              return {
                licensingEvents: [
                  ...existing.licensingEvents,
                  ...incoming.licensingEvents,
                ],
                pageInfo: incoming.pageInfo,
              };
            }
            if (!incoming.pageInfo.hasNextPage) {
              return {
                licensingEvents: [
                  ...existing.licensingEvents,
                  ...incoming.licensingEvents,
                ],
                pageInfo: incoming.pageInfo,
              };
            }

            return {
              licensingEvents: incoming.licensingEvents,
              pageInfo: incoming.pageInfo,
            };
          },
        },
        cartItems: {
          merge(existing: any, incoming: any) {
            return incoming;
          },
        },
      },
    },
  },
});

cache.writeQuery({
  query: CartItemsQuery,
  data: {
    cartItems: [],
  },
});

const headerLink = setContext((_, { headers }) => {
  const csrftoken = getCookie("csrftoken");

  return {
    headers: {
      ...headers,
      "Accept-Language": `${localStorage.i18nextLng}`,
      "X-CSRFToken": csrftoken ? csrftoken : "",
    },
  };
});

const httpLink = new HttpLink({
  uri: `/graphql`,
  credentials: "include",
});

export const client = new ApolloClient({
  cache: cache,
  queryDeduplication: false,
  resolvers: resolvers,
  link: headerLink.concat(httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
  },
});
