import { Grid } from "@mui/material";
import React from "react";

import StatisticsCard from "./StatisticsCard";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";

import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

// Query
import { useQuery } from "@apollo/client";
import { getGenericColor } from "../../utils";
import { graphql } from "../../../../gql";

export const GetDashboardCardsDataQuery = graphql(/* GraphQL */ `
  query getDashboardCardsData {
    dashboard {
      totalNrOfLabels
      totalNrOfLabelsCurrentYear
      totalNrOfRegistrations
      totalNrOfRegistrationsCurrentYear
    }
  }
`);

const DashboardCards = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(GetDashboardCardsDataQuery, {
    fetchPolicy: "no-cache",
  });

  if (error) throw error;

  return (
    <>
      <Grid container mb={3} spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={data && data!.dashboard!.totalNrOfLabels}
            label={t("markings ordered in total")}
            icon={<ArticleIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 0)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={data && data!.dashboard!.totalNrOfLabelsCurrentYear}
            label={t("markings ordered last 12 months")}
            icon={<ArticleIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 1)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={data && data!.dashboard!.totalNrOfRegistrations}
            label={t("registrations in total")}
            icon={<PersonIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 2)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatisticsCard
            value={data && data!.dashboard!.totalNrOfRegistrationsCurrentYear}
            label={t("registrations last 12 months")}
            icon={<PersonIcon />}
            loading={loading}
            bgcolor={getGenericColor(theme, 3)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardCards;
