/// <reference path="../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Grid, Paper, Box } from "@mui/material";
// Components
import { RemoveFromCart } from "../CloseBadge";
import { ProductImage, ProductContent } from "../Product";
import { SKUTag } from "../../Modules/Storefront/components/SKUTag";
// Utils
import { formatValue } from "../../Utils/markingProduct";
// Query
import { useMutation } from "@apollo/client";
// Types

import { draftOrderPositionFragment } from "../../Modules/ShoppingCart/pages/ShoppingCartPage";
import { FragmentType, getFragmentData } from "../../gql";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../gql/graphql";
import { markingProductFragment } from "../../Modules/Storefront/pages/ProductList";
import { removeCartItemMutation } from "../../Modules/ShoppingCart/components/CartPagePaper";
import { useFlag } from "../../orderingFlags";

export const ShoppingCartItem = (props: {
  orderPosition: FragmentType<typeof draftOrderPositionFragment>;
  additionalInfo?: string;
}) => {
  const { t } = useTranslation();
  const { additionalInfo } = props;
  const hidePrices = useFlag(["hidePrices"]);

  // Reading fragment data
  const orderPosition = getFragmentData(
    draftOrderPositionFragment,
    props.orderPosition
  );

  const { quantity, price, nrOfOrderedLabels, total, markable } = orderPosition;

  // Reading fragment data
  const markingProduct = getFragmentData(
    markingProductFragment,
    orderPosition.markingProduct
  );

  const [removeCartByID] = useMutation(removeCartItemMutation);

  return (
    <Box style={{ padding: "15px" }}>
      <Paper style={{ width: "100%", padding: "5px" }}>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs={10}>
            <SKUTag markable={orderPosition.markable.name} />
          </Grid>
          <Grid item xs={2}>
            <RemoveFromCart
              small
              onClick={() =>
                removeCartByID({
                  variables: {
                    markingProductID: markingProduct.uuid,
                    markableID: markable.uuid,
                    additionalInfo: additionalInfo || JSON.stringify({}),
                  },
                })
              }
            />
          </Grid>
        </Grid>
        <Box style={{ height: "100px" }}>
          <ProductImage image={markingProduct.thumbnail.edges} />
        </Box>
        <Box style={{ padding: "0 5px" }}>
          {markingProduct.markingProductType === ProductType.Digital && (
            <ProductContent
              price={price}
              hide={
                hidePrices
                  ? ["price", "dimensions", "unit"]
                  : ["dimensions", "unit"]
              }
              markingProduct={orderPosition.markingProduct}
              additionalInfo={additionalInfo}
              markableId={markable.uuid}
              quantity={quantity}
              primary={{
                [`${t("N° of Codes")}`]: `${formatValue("number", quantity)}`,
                [`${t("Subtotal")}`]: hidePrices
                  ? "-"
                  : `${formatValue("currency", total)}`,
              }}
            />
          )}

          {markingProduct.markingProductType === ProductType.Physical && (
            <ProductContent
              price={price}
              hide={["dimensions", "unit"]}
              markingProduct={orderPosition.markingProduct}
              quantity={quantity}
              primary={{
                [`${t("N° of rolls")}`]: `${formatValue("number", quantity)}`,
                [`${t("N° of labels")}`]: `${formatValue(
                  "number",
                  nrOfOrderedLabels
                )}`,
                [`${t("Subtotal")}`]: `${formatValue("currency", total)}`,
              }}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};
