/// <reference path="../../Types/index.d.ts" />
/// <reference path="../../Types/createMuiTheme.d.ts" />

import React, { useState, useMemo } from "react";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { BrandContext } from "./BrandContext";

interface createThemesFromBrand {
  (config: BrandContext.Brand): { defaultTheme: any; mobileTheme: any };
}

export const createThemesFromBrand: createThemesFromBrand = (config) => {
  // clone material palette options, because createMuiTheme adds values in place!

  const materialPalette = cloneDeep(config.palette.material);

  // check for a statisticsDashboard lookup color palette
  let dashboardLookupPalette = config.palette.statisticsDashboard;
  if (!dashboardLookupPalette) {
    // no statisticsDashboard lookup, create a default version with 6 entries
    // because currently we have 6 statisticsDashboard cards
    // we assume that at least one generic color is given
    dashboardLookupPalette = [0, 0, 0, 0, 0, 0];
    for (let i = 0; i < dashboardLookupPalette.length; i++) {
      dashboardLookupPalette[i] = i % config.palette.generic.length;
    }
  }

  const defaultTheme = createTheme({
    palette: materialPalette,
    generic: config.palette.generic,
    statisticsDashboard: dashboardLookupPalette,
    typography: config.typography.default,
  });

  const mobileTheme = createTheme({
    palette: materialPalette,
    generic: config.palette.generic,
    statisticsDashboard: dashboardLookupPalette,
    typography: config.typography.mobile,
  });
  return { defaultTheme, mobileTheme };
};

export const BrandContextProvider: React.FC<{ config: BrandContext.Brand }> = ({
  config,
  children,
}) => {
  const [brand, setBrand] = useState(config);
  const { defaultTheme, mobileTheme } = useMemo(
    () => createThemesFromBrand(brand),
    [brand]
  );

  const isMobile = useMediaQuery(defaultTheme.breakpoints.down("xs"));
  const theme = isMobile ? mobileTheme : defaultTheme;

  return (
    <BrandContext.Provider
      value={{
        brand,
        theme,
        getBrandValue: (path: string, defaultValue = undefined) => {
          return get(brand, path, defaultValue);
        },
        setBrandValue: (path: string, value: any) => {
          let newBrand = cloneDeep(brand);
          // handling of palette.material.background.default is special:
          // 1. we determine the light/dark setting from the given (background) color
          // 2. we set paper color to the light variant of the given (background) color
          if (path === "palette.material.background.default") {
            const contrastTextColor =
              defaultTheme.palette.getContrastText(value);
            const type =
              contrastTextColor === defaultTheme.palette.common.white
                ? "dark"
                : "light";
            set(newBrand, "palette.material.type", type);
            const augmentedBackgroundColor = defaultTheme.palette.augmentColor({
              main: value,
            });
            set(
              newBrand,
              "palette.material.background.paper",
              augmentedBackgroundColor.light
            );
          }
          set(newBrand, path, value);
          setBrand(newBrand);
        },
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};
