import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect } from "react-router-dom";
// Components
import PageContainer from "../../Components/PageContainer";
import { ShoppingCart as ShoppingCartPage } from "./pages/ShoppingCartPage";
import { CartEmpty } from "../../Components/CartEmpty";
//Query
import { useQuery } from "@apollo/client";
import { LoadingIndicator } from "../../Components/LoadingIndicator";
import { graphql } from "../../gql";

export const CartItemsQuery = graphql(/* GraphQL */ `
  query cartItems {
    cartItems @client {
      ...CartItems
    }
  }
`);

export const cartItemsFragment = graphql(/* GraphQL */ `
  fragment CartItems on ShoppingCart {
    markingProduct {
      uuid
      nart
      name
      description
      specification
      markingProductType
      customerReference
      labelLength
      labelWidth
      labelsPerUnit
      weightPerUnit
      unitLength
      unitWidth
      unitHeight
      enabled
      pricingRules {
        price
        minQuantity
        scaleBasis
      }
      markables {
        edges {
          node {
            uuid
            name
            description
          }
        }
      }
    }
    quantity
    markable
    additionalInfo
  }
`);

export const ShoppingCart = () => {
  const { t } = useTranslation();
  const header = [{ name: t("Cart Items"), urlRegex: /^\/shoppingcart\/list/ }];

  const { data, loading, error } = useQuery(CartItemsQuery);
  if (error) throw error;

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <PageContainer
      title={t("SHOPPING CART")}
      subTitle={""}
      header={{ breadcrumbs: header }}
    >
      {data && data.cartItems && data.cartItems.length > 0 ? (
        <Switch>
          <Route
            exact
            path={"/shoppingcart/list"}
            render={() => <ShoppingCartPage cartItems={data.cartItems} />}
          />
          <Redirect from="/shoppingcart" to="/shoppingcart/list" />
          <Route exact path={"/error"} />
        </Switch>
      ) : (
        <CartEmpty />
      )}
    </PageContainer>
  );
};
