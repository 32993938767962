/// <reference path="../../../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";

//Query
import { useQuery } from "@apollo/client";

import { PlaceOrderMutationInput as OrderInput } from "../../../../gql/graphql";

import { Step } from "./types";

import { LoadingIndicator } from "../../../../Components/LoadingIndicator";

import { AddressFormControl } from "../AddressFormControl";
import { PurchaseOrderNumber } from "../PurchaseOrderNumber";
import {
  myCompanyQuery,
  addressFragment,
  companyFragment,
} from "../../../CompanyProfile/CompanyProfile";
import { getFragmentData } from "../../../../gql";

const StepComponent: Step["handler"] = (props) => {
  const { t } = useTranslation();
  const { order, updateOrder } = props;

  const { data, loading, error } = useQuery(myCompanyQuery);

  if (loading) return <LoadingIndicator />;

  if (error) throw error;

  // Reading fragment data
  const myCompanyData = getFragmentData(companyFragment, data?.myCompany);

  if (!data || !data.myCompany || !myCompanyData?.billingAddresses)
    throw new Error(t("company information is incomplete"));

  const billingAddresses = myCompanyData.billingAddresses.edges.map((item) => {
    const address = getFragmentData(addressFragment, item?.node);
    if (!address) throw Error;
    return address;
  });

  const onAddressChange = (orderUpdate: Partial<OrderInput>) => {
    updateOrder((order: any) => ({ ...order, ...orderUpdate }));
  };

  const onChange = (order: Partial<OrderInput>) => {
    updateOrder((oldOrder: any) => ({ ...oldOrder, ...order }));
  };

  return (
    <div>
      <AddressFormControl
        name={"billingAddress"}
        title={t("Billing Address")}
        hideDefault={true}
        addresses={billingAddresses}
        onChange={onAddressChange}
        value={order.billingAddress ? order.billingAddress : undefined}
      />

      <PurchaseOrderNumber
        onChange={onChange}
        purchaseOrderNumber={order.purchaseOrderNumber}
      />
    </div>
  );
};

export const useStepDefinition = (): Step => {
  const { t } = useTranslation();

  return {
    title: t("Billing Information"),
    id: "billingInformation",
    handler: StepComponent,
    isValid: (order, activeStep) => {
      return (
        order &&
        order.billingAddress !== undefined &&
        activeStep >= 0 &&
        !(
          order.purchaseOrderNumber &&
          order.purchaseOrderNumber !== undefined &&
          order.purchaseOrderNumber.length > 15
        )
      );
    },
  };
};

export const StepDefinition = { useStepDefinition };
