import React from "react";
// Utils
import { formatValue } from "../../../Utils/markingProduct";
// Material-UI
import { Typography, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
//Query
import { useMutation } from "@apollo/client";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";

// import { useFlag } from "../../../orderingFlags";
import { UpdateCartMutation } from "../../Storefront/components/Content";

export const Counter = (props: { cartItem: any }) => {
  let { markingProduct, quantity, markable, additionalInfo } = props.cartItem;
  //const minQuantity = getMinQuantity(markingProduct.pricingRules);

  //const minOrderQuantity = useFlag(["minimumOrder"]);
  //const value = minOrderQuantity ? minQuantity : 1000;
  const value = markingProduct.quantityIncrement;

  const [cartUpdate, { data, error }] = useMutation(UpdateCartMutation);

  if (error) throw error;

  if (data && data.updateCartItem) {
    quantity = data.updateCartItem.quantity;
  }

  const changeQuantity = (change: number) => {
    if (quantity + change >= 1) {
      cartUpdate({
        variables: {
          cartItem: {
            markingProductID: markingProduct.uuid,
            quantity: change,
            markable: markable.uuid,
            // 18/7/24-stf: physical products don't have additional info,
            // but the mutation still requires it
            additionalInfo: additionalInfo || JSON.stringify({}),
          },
        },
      });
    }
  };

  const rollsCount = formatValue("number", quantity);
  return (
    <>
      {markingProduct.markingProductType === ProductType.Digital ? (
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButton
            onClick={() => changeQuantity(-value)}
            color="primary"
            disabled={markingProduct.minOrderQuantity === quantity}
          >
            <RemoveIcon />
          </IconButton>
          <Typography variant="button">{rollsCount}</Typography>
          <IconButton onClick={() => changeQuantity(value)} color="primary">
            <AddIcon />
          </IconButton>
        </Grid>
      ) : (
        <Grid container justifyContent="space-between" alignItems="center">
          <IconButton onClick={() => changeQuantity(-1)} color="primary">
            <RemoveIcon />
          </IconButton>
          <Typography variant="button">{rollsCount}</Typography>
          <IconButton onClick={() => changeQuantity(1)} color="primary">
            <AddIcon />
          </IconButton>
        </Grid>
      )}
    </>
  );
};
