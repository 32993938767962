import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Grid, Typography } from "@mui/material";
// Components
import { Label } from "./ProductLabel";
// Types
import { markingProductFragment } from "../../Modules/Storefront/pages/ProductList";
import { FragmentType, getFragmentData } from "../../gql";
import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../gql/graphql";


export const ProductDetails = (props: {
  markingProduct: FragmentType<typeof markingProductFragment>;
  price?: string;
  labelsPerUnit?: string;
  dimensions?: string;
}) => {
  const { price, labelsPerUnit, dimensions, markingProduct } = props;
  const { t } = useTranslation();
  // Reading fragment data
  const product = getFragmentData(
    markingProductFragment,
    markingProduct
  );

  return (
    <>
      {price && (
        <Grid container item justifyContent="space-between">
          <Typography variant="subtitle2" color="textSecondary">
            {t(`Price`)}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {price}
            <Label markingProduct={markingProduct} />
          </Typography>
        </Grid>
      )}
      {labelsPerUnit && (
        <Grid container item justifyContent="space-between">
          <Typography variant="subtitle2" color="textSecondary">
            {t(`Description`)}
          </Typography>

          <Typography variant="subtitle2" color="textSecondary">
            {product.markingProductType === ProductType.Digital
              ? t("Codes")
              : `${labelsPerUnit} ${t("labels / roll")}`}
          </Typography>
        </Grid>
      )}
      {dimensions && (
        <Grid container item justifyContent="space-between">
          <Typography variant="subtitle2" color="textSecondary">
            {t(`Dimensions`)}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {dimensions}
          </Typography>
        </Grid>
      )}
    </>
  );
};
