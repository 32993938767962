/// <reference path="../../../Types/index.d.ts" />

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography, Grid, Paper, Button, Divider } from "@mui/material";
import ArrowIconRight from "@mui/icons-material/KeyboardArrowRight";
// Components
import { InfoBar } from "../../../Components/InfoBar";
import { ProductPaper } from "../components/CartPagePaper";
import { AlertMessage } from "../components/AlertMessage";
import { CartTitle } from "../components/CartTitle";
import { ShoppingCartSubTotal } from "../components/ShoppingCartSubTotal";
//Query
import { useQuery } from "@apollo/client";
import { FragmentType, getFragmentData, graphql } from "../../../gql";
import { cartItemsFragment } from "../ShoppingCart";

export const DraftOrderQuery = graphql(/* GraphQL */ `
  query draftOrder(
    $positions: [OrderPositionInput!]!
    $deliveryAddress: UUID
    $transportationMethod: OrderOrderTransportationMethodChoices
  ) {
    draftOrder(
      positions: $positions
      deliveryAddress: $deliveryAddress
      transportationMethod: $transportationMethod
    ) {
      orderPositions {
        ...DraftOrderPosition
      }
      ...DraftOrder
    }
  }
`);

export const draftOrderFragment = graphql(/* GraphQL */ `
  fragment DraftOrder on DraftOrderNode {
    deliveryCosts
    handlingCosts
    subTotal
    total
  }
`);

export const draftOrderPositionFragment = graphql(/* GraphQL */ `
  fragment DraftOrderPosition on DraftOrderPositionNode {
    markable {
      uuid
      name
    }
    markingProduct {
      ...MarkingProduct
    }
    price
    quantity
    total
    scaleBasis
    currency
    nrOfOrderedLabels
    additionalInfo
  }
`);

export const DraftVolumeQuery = graphql(/* GraphQL */ `
  query draftVolume($positions: [OrderPositionInput!]!) {
    draftVolume(positions: $positions) {
      markable {
        uuid
        name
        description
      }
      consumedQuota
      quota
      remainingQuota
      status
      nrOfOrderedLabels
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      //borderBottom: `1px solid ${theme.palette.primary.main}`
    },
    typography: {
      cursor: "pointer",
      verticalAlign: "middle",
      display: "inline-block",
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    icon: {
      verticalAlign: "middle",
      display: "inline-block",
    },
  })
);

interface categorizeOrderPositions {
  [key: string]: sub;
}

interface sub {
  volume: any;
  orderPositions: any[];
}

export const ShoppingCart = (props: {
  cartItems: FragmentType<typeof cartItemsFragment>[];
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  // Reading fragment data
  const cartItems = getFragmentData(cartItemsFragment, props.cartItems);

  const inputVariables = {
    positions: cartItems.map((ci) => ({
      markingProduct: ci.markingProduct.uuid,
      quantity: ci.quantity,
      markable: ci.markable,
      additionalInfo: ci.additionalInfo,
    })),
  };

  const {
    data: draftOrderData,
    loading: draftOrderLoading,
    error: draftOrderError,
  } = useQuery(DraftOrderQuery, {
    variables: inputVariables,
    fetchPolicy: "network-only",
  });

  if (draftOrderError) throw draftOrderError;

  const {
    data: draftVolumeData,
    loading: draftVolumeLoading,
    error: draftVolumeError,
  } = useQuery(DraftVolumeQuery, {
    variables: inputVariables,
    fetchPolicy: "network-only",
  });

  if (draftVolumeError) throw draftVolumeError;

  const isExcessVolume = draftVolumeData
    ? draftVolumeData.draftVolume &&
      draftVolumeData.draftVolume.filter(
        (volume: any) => volume && volume.status === "QUOTA_EXCEEDED"
      ).length > 0
    : false;

  let categorizedOrderPositions: categorizeOrderPositions = {};

  const types = Array.from(
    new Set(cartItems.map((item) => item.markingProduct.markingProductType))
  );

  // no category needed if SKU part added, compare with selected SKU and set the same category
  if (
    draftOrderData &&
    draftOrderData.draftOrder &&
    draftVolumeData &&
    draftVolumeData.draftVolume
  ) {
    categorizedOrderPositions = draftOrderData.draftOrder.orderPositions.reduce(
      (acc: any, item: any) => {
        const group: any = item.markable;

        if (!acc[group.name]) {
          acc[group.name] = {};
          acc[group.name]["orderPositions"] = [];
          acc[group.name]["volume"] = draftVolumeData.draftVolume.find(
            (volume: any) =>
              volume &&
              volume.markable &&
              volume.markable.id === item.markable.id
          );
        }
        acc[group.name]["orderPositions"].push({
          ...item,
        });
        return acc;
      },
      {}
    );
  }

  return draftOrderData &&
    draftOrderData.draftOrder &&
    draftVolumeData &&
    draftVolumeData.draftVolume &&
    draftVolumeData.draftVolume.length > 0 &&
    categorizedOrderPositions ? (
    <>
      <InfoBar
        left={[<CartTitle key={0} summedUpProducts={cartItems.length} />]}
        withBottomMargin
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          {Object.keys(categorizedOrderPositions).map((key, index) => {
            const category = categorizedOrderPositions[key];

            const orderPositions = category.orderPositions;
            const volume = category && category.volume;
            return orderPositions && volume ? (
              <Grid key={index} container item style={{ marginBottom: "16px" }}>
                <Paper elevation={1} style={{ width: "100%" }}>
                  <div id="header" style={{ padding: "14px" }}>
                    <Typography variant="h6" display="block">
                      {key.toUpperCase()}
                    </Typography>
                  </div>
                  <Divider />
                  {volume.status === "QUOTA_EXCEEDED" && (
                    <div id="message">
                      <AlertMessage
                        excess_quantity={
                          category.volume.nrOfOrderedLabels -
                          category.volume.remainingQuota
                        }
                      />
                      <Divider />
                    </div>
                  )}
                  <div style={{ padding: "14px 14px 0 14px" }}>
                    {orderPositions.map((orderPosition, index) => {
                      const cartItem = cartItems.find((ci) => {
                        return (
                          ci.markable === orderPosition.markable.uuid &&
                          ci.markingProduct.uuid ===
                            orderPosition.markingProduct.uuid &&
                          JSON.stringify(JSON.parse(ci.additionalInfo)) ===
                            JSON.stringify(
                              JSON.parse(orderPosition.additionalInfo)
                            )
                        );
                      });
                      return (
                        <ProductPaper
                          key={index}
                          orderPosition={orderPosition}
                          additionalInfo={cartItem?.additionalInfo}
                          loading={
                            draftVolumeLoading &&
                            draftOrderLoading &&
                            cartItem !== undefined &&
                            cartItem.quantity !== orderPosition.quantity
                          }
                        />
                      );
                    })}
                  </div>
                </Paper>
              </Grid>
            ) : null;
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <ShoppingCartSubTotal
            draftOrder={draftOrderData.draftOrder}
            types={types}
          />
          {isExcessVolume && (
            <Typography color="error" style={{ padding: "10px" }}>
              {`${t("Volume Violation")}: `} <br />
              {t("Please adapt the quantities for the affected category.")}
            </Typography>
          )}
          {types.length > 1 && (
            <Typography color="error" style={{ padding: "10px" }}>
              {`${t("Attention")}: `} <br />
              {t(
                "You can not order labels and digital products in a single order. Please remove items from one of these categories before proceeding."
              )}
            </Typography>
          )}
          {isExcessVolume || types.length > 1 ? (
            <Button fullWidth variant="contained" color="primary" disabled>
              {t("Proceed With Checkout")}
            </Button>
          ) : (
            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <Button fullWidth variant="contained" color="primary">
                {t("Proceed With Checkout")}
              </Button>
            </Link>
          )}

          <div style={{ textAlign: "center", padding: "8px 0" }}>
            <Link to="/storefront/products" className={classes.link}>
              <Typography variant="button" className={classes.typography}>
                {t("Back to Storefront")}
              </Typography>
              <ArrowIconRight className={classes.icon} />
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  ) : !draftOrderLoading ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "300px",
      }}
    >
      <Typography variant="h4" gutterBottom>
        {t("Volume Violation: You don't have contract")}
      </Typography>
    </div>
  ) : null;
};
