import React from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";
import { Redirect } from "react-router";
import { useSnackbar } from "notistack";

// Material-UI
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

import { Typography, Grid, Button, Divider, Paper } from "@mui/material";

// Components
import { InfoBar } from "../../../Components/InfoBar";
import { ArrowLinkLeft } from "../../../Components/CustomizeLink";
import { ProductElement } from "../../../Components/Product";
import { DivCheckBox } from "../../../Components/DivCheckBox";
import { OrderIDHeader } from "../components/OrderIDHeader";
import { formatOrderId } from "../../../Utils/commons";

//Queries
import { useQuery, useMutation } from "@apollo/client";

//Utils
import { formatValue } from "../../../Utils/markingProduct";
import { LoadingIndicator } from "../../../Components/LoadingIndicator";
import { SKUTag } from "../../Storefront/components/SKUTag";
import { getFragmentData, graphql } from "../../../gql";
import {
  GetOrderQuery,
  orderFragment,
  orderPositionFragment,
} from "./OrderDetail";
import { UpdateCartMutation } from "../../Storefront/components/Content";
import { markingProductFragment } from "../../Storefront/pages/ProductList";
import { useFlag } from "../../../orderingFlags";

export const CancelOrderMutation = graphql(/* GraphQL */ `
  mutation cancelOrder($input: RequestOrderCancelMutationInput!) {
    requestOrderCancel(input: $input) {
      success
      errors
    }
  }
`);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    productElement: {
      padding: theme.spacing(2),
    },
  })
);

interface params {
  id: string;
}

const Header = ({ text }: { text: string }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <Typography variant="h5" component="h3">
        {text}
      </Typography>
    </Grid>
  );
};

export const OrderCancel = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  //const { enqueueSnackbar } = useSnackbar();

  const { match } = useReactRouter();
  const orderID = (match.params as params).id;

  const { data, loading, error } = useQuery(GetOrderQuery, {
    variables: { uuid: orderID },
  });

  const [cancelOrder] = useMutation(CancelOrderMutation);

  if (error) throw error;

  if (!data?.order) return <Typography>{t("No orders")}</Typography>;
  // Reading fragment data
  const order = getFragmentData(orderFragment, data?.order);

  // if (mutationError) throw mutationError;

  //const order = data && data.order ? data.order : null;

  if (order && !order.canCancel) {
    return <Redirect to={`/orders/${order.uuid}`} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const cancel = async () => {
    try {
      const { data } = await cancelOrder({
        variables: { input: { uuid: order.uuid } },
        refetchQueries: () => [
          { query: GetOrderQuery, variables: { uuid: order.uuid } },
        ],
      });
      if (data?.requestOrderCancel!.errors) {
        console.log(data.requestOrderCancel.errors);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return order ? (
    <>
      <InfoBar
        left={[<OrderIDHeader id={formatOrderId(order.orderId)} key={0} />]}
        withBottomMargin
      />
      <Paper>
        <Header text={t("Review Products in Order")} />
        <Divider />
        <Grid container>
          {order.orderPositions.edges.map((position, index: number) => {
            const orderPosition = getFragmentData(
              orderPositionFragment,
              position?.node
            );
            if (!orderPosition) throw Error;
            const {
              markingProduct,
              quantity,
              total,
              price,
              markable,
              additionalInfo,
            } = orderPosition;
            return (
              <Grid
                item
                xs={12}
                md={6}
                className={classes.productElement}
                key={index}
              >
                <Grid item>
                  <SKUTag markable={markable.name} />
                </Grid>
                <ProductElement
                  price={price}
                  product={markingProduct}
                  quantity={quantity}
                  primary={{
                    [`${t("Subtotal")}`]: formatValue("currency", total),
                  }}
                  cardSize={200}
                  additionalInfo={additionalInfo}
                  markableId={markable.uuid}
                />
              </Grid>
            );
          })}
        </Grid>

        <Divider />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              style={{ margin: "20px" }}
              variant="contained"
              color="secondary"
              onClick={cancel}
            >
              {t("Confirm to Cancel")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <div style={{ padding: "16px 0" }}>
        <ArrowLinkLeft href={"/orders"} key={0}>
          {t("Back To Orders")}
        </ArrowLinkLeft>
      </div>
    </>
  ) : null;
};

export const OrderRepeat = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { enqueueSnackbar } = useSnackbar();
  const hidePrices = useFlag(["hidePrices"])
  const { match } = useReactRouter();
  const orderID = (match.params as params).id;

  const { data, loading, error } = useQuery(GetOrderQuery, {
    variables: { uuid: orderID },
  });

  if (error) throw error;

  if (!data?.order) throw new Error();
  // Reading fragment data
  const order = getFragmentData(orderFragment, data?.order);

  const [items, setItems] = React.useState<object[]>([]);

  const [updateCart, { error: mutationError }] =
    useMutation(UpdateCartMutation);

  if (mutationError) throw mutationError;

  const handleChange = (value: any) => {
    const markingProductID = value.markingProductID;
    const markable = value.markable;
    const additionalInfo = value.additionalInfo;

    if (
      items.some(
        (item: any) =>
          item.markingProductID === markingProductID &&
          item.markable === markable &&
          item.additionalInfo === additionalInfo
      )
    ) {
      setItems([
        ...items.filter(
          (item: any) =>
            item.markingProductID !== markingProductID ||
            item.markable !== markable ||
            item.additionalInfo !== additionalInfo
        ),
      ]);
    } else {
      setItems([...items, value]);
    }
  };

  const addToCart = async () => {
    if (!order) throw new Error();
    const cartItems = order.orderPositions.edges.filter((position) => {
      const orderPosition = getFragmentData(
        orderPositionFragment,
        position?.node
      );
      const product = getFragmentData(
        markingProductFragment,
        orderPosition?.markingProduct
      );
      return items.some(
        (item: any) =>
          item.markingProductID === product?.uuid &&
          item.markable.uuid === orderPosition?.markable.uuid &&
          item.additionalInfo === orderPosition?.additionalInfo
      );
    });
    // dont use forEach. updateCart is an async.
    for (let cartItem of cartItems) {
      const position = getFragmentData(orderPositionFragment, cartItem?.node);
      const product = getFragmentData(
        markingProductFragment,
        position?.markingProduct
      );
      if (!position || !product) throw error;

      await updateCart({
        variables: {
          cartItem: {
            markingProductID: product.uuid,
            quantity: position.quantity,
            markable: position.markable.uuid,
            additionalInfo:
              Object.keys(position.additionalInfo).length > 0
                ? position.additionalInfo
                : null,
          },
        },
      });
    }
    setItems([]);
    enqueueSnackbar(t("Successfully added to your shopping cart"), {
      variant: "success",
    });
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return data && data.order ? (
    <>
      <InfoBar
        left={[<OrderIDHeader id={formatOrderId(order.orderId)} key={0} />]}
        withBottomMargin
      />

      <Paper>
        <Header text={t("Choose Products")} />
        <Divider />
        <Grid container>
          {order.orderPositions.edges.map((position, index: number) => {
            const orderPosition = getFragmentData(
              orderPositionFragment,
              position?.node
            );
            if (!orderPosition) throw error;
            const { quantity, total, price, markable, additionalInfo } =
              orderPosition;
            const product = getFragmentData(
              markingProductFragment,
              orderPosition?.markingProduct
            );
            return (
              <Grid
                item
                xs={12}
                md={6}
                className={classes.productElement}
                key={index}
              >
                <Grid item>
                  <SKUTag markable={markable.name} />
                </Grid>
                <DivCheckBox
                  checked={items.some(
                    (item: any) =>
                      item.markingProductID === product.uuid &&
                      item.markable === markable &&
                      item.additionalInfo === additionalInfo
                  )}
                  onChange={handleChange}
                  value={{
                    markingProductID: product.uuid,
                    markable: markable,
                    additionalInfo: additionalInfo,
                  }}
                >
                  <ProductElement
                    price={price}
                    product={orderPosition?.markingProduct}
                    quantity={quantity}
                    primary={{
                      [`${t("Subtotal")}`]: hidePrices ? "-" : formatValue(
                        "currency",
                        total
                      ),
                    }}
                    cardSize={200}
                    additionalInfo={additionalInfo}
                    markableId={markable.uuid}
                  />
                </DivCheckBox>
              </Grid>
            );
          })}
        </Grid>
        <Divider />
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              style={{ margin: "20px" }}
              variant="contained"
              color="primary"
              disabled={items.length === 0}
              onClick={addToCart}
            >
              {t("Add To Cart")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <div style={{ padding: "16px 0" }}>
        <ArrowLinkLeft href={"/orders"} key={0}>
          {t("Back To Orders")}
        </ArrowLinkLeft>
      </div>
    </>
  ) : (
    <LoadingIndicator />
  );
};
