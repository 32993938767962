import React from "react";

import Typography from "@mui/material/Typography";
import { FragmentType, getFragmentData } from "../../../gql/fragment-masking";
import { markingProductFragment } from "../pages/ProductList";

export const ProductName = (props: {
  markingProduct: FragmentType<typeof markingProductFragment>;
}) => {
  const markingProduct = getFragmentData(
    markingProductFragment,
    props.markingProduct
  );

  return <Typography variant="overline">{markingProduct.name}</Typography>;
};
