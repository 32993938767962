import * as React from "react";
import { useTranslation } from "react-i18next";

// Material UI
import { Autocomplete, TextField } from "@mui/material";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { useMarkableFilter } from "../../../Providers/MarkableContext";

export const MarkableFilter = (props: { productGroup: any[] }) => {
  const { t } = useTranslation();

  const { filterValue, setFilterValue } = useMarkableFilter();
  const { productGroup } = props;

  return (
    <>
      <Autocomplete
        freeSolo
        id="markable-filter"
        options={productGroup}
        value={filterValue}
        onChange={(event, newValue) => {
          setFilterValue(newValue);
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        style={{ width: 400 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Please select the relevant article")}
            margin="normal"
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option, inputValue);
          const parts = parse(option, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index: number) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    </>
  );
};
