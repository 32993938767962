import React from "react";
import { useTranslation } from "react-i18next";
// Material-UI
import { Tabs, Tab, Typography, Box } from "@mui/material";
// Components
import { MarkableFilter } from "./MarkableFilter";
import { useMarkableFilter } from "../../../Providers/MarkableContext";
import { ProductsByGroup } from "../components/ProductsByGroup";
import DigitalProducts from "./DigitalProducts";
// Utils
import {
  getGroupProducts,
  getProductGroups,
} from "../../../Utils/markingProduct";

import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
import { FragmentType, getFragmentData } from "../../../gql";
import { markableFragment, markingProductFragment } from "../pages/ProductList";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ProductTab = (props: {
  markingProducts: FragmentType<typeof markingProductFragment>[];
  groups: FragmentType<typeof markableFragment>[];
}) => {
  const { t } = useTranslation();
  const { markingProducts, groups } = props;
  const { setFilterValue } = useMarkableFilter();
  const [value, setValue] = React.useState(0);
  const markables = getFragmentData(markableFragment, groups);

  // filter only allowed digital product groups
  const digitalproductGroups = markables
    .map((item) => item && item.name)
    .filter((value) =>
      getProductGroups(markingProducts, ProductType.Digital).find(
        (name) => name === value
      )
    );

  const physicalProductsByGroup = getGroupProducts(
    markingProducts,
    ProductType.Physical
  );
  const digitalProductsByGroup = getGroupProducts(
    markingProducts,
    ProductType.Digital
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setFilterValue("");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={t("Labels")} />
        <Tab label={t("Direct Print")} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <ProductsByGroup
          productsByGroups={physicalProductsByGroup}
          groups={groups}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        {digitalProductsByGroup.length > 1 ? (
          <>
            <MarkableFilter productGroup={digitalproductGroups} />
            <DigitalProducts
              markingProducts={markingProducts}
              groups={groups}
            />
          </>
        ) : (
          <ProductsByGroup
            productsByGroups={digitalProductsByGroup}
            groups={groups}
          />
        )}
      </TabPanel>
    </Box>
  );
};
