import React from "react";

import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import Empty from "../../../../Components/Empty";
import { MonthlyVolume } from "../../../../gql/graphql";

/* Fetch last 12 months from current month */
const getLastMonths = (dataset: MonthlyVolume[]) => {
  const monthNames =
    dataset && dataset.map((item: MonthlyVolume) => item.monthName);
  const uniqueNames = monthNames?.filter((element, index: number) => {
    return monthNames.indexOf(element) !== index;
  });

  const lastMonths = uniqueNames?.map((obj) => obj.substr(0, 3));

  return lastMonths;
};

const ordersForYear = (year: number, dataset: MonthlyVolume[]) => {
  const currentYear = new Date().getFullYear();

  // filter data based on x-axis
  const data =
    dataset &&
    dataset
      .filter((obj: MonthlyVolume) => obj.year === year)
      .sort((a, b) => {
        return (
          getLastMonths(dataset).indexOf(a.monthName.substr(0, 3)) -
          getLastMonths(dataset).indexOf(b.monthName.substr(0, 3))
        );
      })
      .reverse();

  if (year === currentYear - 1) {
    return data?.map((obj: MonthlyVolume) => obj.volume);
  }

  if (year === currentYear) {
    const volumesCurrentYear = data?.map((obj: MonthlyVolume) => obj.volume);

    const data1 =
      dataset &&
      dataset
        .filter((obj: MonthlyVolume) => obj.year === currentYear - 2)
        .sort((a, b) => {
          return (
            getLastMonths(dataset).indexOf(a.monthName.substr(0, 3)) -
            getLastMonths(dataset).indexOf(b.monthName.substr(0, 3))
          );
        })
        .reverse();

    const volumeslastYear = data1?.map((obj: MonthlyVolume) => obj.volume);

    return volumeslastYear?.concat(volumesCurrentYear);
  }
};

const CompareChart = (props: { dataset: MonthlyVolume[] }) => {
  const { dataset } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const currentYear = new Date().getFullYear();
  const noVolume =
    dataset &&
    dataset.map((obj: MonthlyVolume) => obj.volume).every((item) => item === 0);

  const option = {
    legend: {
      data: [t("Last period"), t("Current period")],
      top: "top",
      selectedMode: false,
    },
    tooltip: { trigger: "axis" },
    xAxis: [
      {
        type: "category",
        data: getLastMonths(dataset).reverse(),
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: (value: any, index: number) => {
            return new Intl.NumberFormat(i18n.language, {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(value);
          },
        },
      },
    ],
    series: [
      {
        name: "Last period",
        type: "bar",
        data: ordersForYear(currentYear - 1, dataset),
        color: theme.palette.secondary.light,
      },
      {
        name: "Current period",
        type: "bar",
        data: ordersForYear(currentYear, dataset),
        color: theme.palette.primary.light,
      },
    ],
  };

  return noVolume ? (
    <Empty />
  ) : (
    <>
      <ReactECharts option={option} />
    </>
  );
};

export default CompareChart;
