/// <reference path="../../../Types/index.d.ts" />
import React from "react";
import { useTranslation } from "react-i18next";
// Utils
import { formatValue } from "../../../Utils/markingProduct";
// Material-UI
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  LinearProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
//Query
import { useMutation } from "@apollo/client";

import { MarkingProductsMarkingProductMarkingProductTypeChoices as ProductType } from "../../../gql/graphql";
// components
import { ProductImage, ProductContent } from "../../../Components/Product";
import { Counter } from "./Counter";
import { graphql } from "../../../gql";
import { useFlag } from "../../../orderingFlags";

export const removeCartItemMutation = graphql(/* GraphQL */ `
  mutation removeCartItem(
    $markingProductID: UUID
    $markableID: UUID
    $additionalInfo: JSONString
  ) {
    removeCartItem(
      markingProductID: $markingProductID
      markableID: $markableID
      additionalInfo: $additionalInfo
    ) @client(always: true)
  }
`);

export const ProductPaper = (props: {
  orderPosition: any;
  loading: boolean;
  additionalInfo?: string | undefined;
}) => {
  const { t } = useTranslation();
  const additionalInfo = props.additionalInfo;
  const { markingProduct, quantity, nrOfOrderedLabels, total, markable } =
    props.orderPosition;
  const [removeCartByID, { error }] = useMutation(removeCartItemMutation);
  const hidePrices = useFlag(["hidePrices"]);

  if (error) throw error;

  return (
    <Paper
      elevation={0}
      style={{
        width: "100%",
        outline: `1px solid #e0e0e0`,
        marginBottom: "14px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={4} style={{ height: "196px" }}>
          <ProductImage image={markingProduct.thumbnail.edges} />
        </Grid>
        <Grid
          item
          xs={12}
          sm
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item container direction="column">
            <div style={{ height: "22px" }} />
            <Grid item xs={12} sm container>
              <Grid
                item
                xs={12}
                sm={5}
                style={{ paddingLeft: "16px", paddingRight: "16px" }}
              >
                <ProductContent
                  markingProduct={markingProduct}
                  quantity={quantity}
                  additionalInfo={additionalInfo}
                  hide={
                    hidePrices
                      ? ["price", "dimensions", "unit"]
                      : ["dimensions"]
                  }
                  markableId={markable.uuid}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm
                style={{ paddingLeft: "16px", paddingRight: "16px" }}
              >
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      style={{ padding: "12px", paddingLeft: 0 }}
                    >
                      {markingProduct.markingProductType === ProductType.Digital
                        ? t("N° of Codes")
                        : t("N° of rolls")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {markingProduct.quantityIncrement > 0 ? (
                      <Counter
                        cartItem={{
                          quantity: quantity,
                          markingProduct: markingProduct,
                          markable: markable,
                          additionalInfo: additionalInfo,
                        }}
                      />
                    ) : (
                      <Grid item> {markingProduct.minOrderQuantity} </Grid>
                    )}
                  </Grid>
                </Grid>
                {markingProduct.markingProductType === ProductType.Physical && (
                  <Grid item container justifyContent="space-between">
                    <Typography variant="subtitle1">
                      {t("N° of labels")}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{ paddingRight: "12px" }}
                    >
                      {formatValue("number", nrOfOrderedLabels)}
                    </Typography>
                  </Grid>
                )}

                <Grid item container xs={12} justifyContent="space-between">
                  <Typography variant="subtitle1">{t("Subtotal")}</Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ paddingRight: "12px" }}
                  >
                    {hidePrices ? "-" : formatValue("currency", total)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ height: "44px", paddingRight: "16px" }}>
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                <IconButton
                  color="primary"
                  aria-label="delete"
                  onClick={() => {
                    removeCartByID({
                      variables: {
                        markingProductID: markingProduct.uuid,
                        markableID: markable.uuid,
                        additionalInfo: additionalInfo || JSON.stringify({}),
                      },
                    });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {props.loading && <LinearProgress variant="query" />}
    </Paper>
  );
};
