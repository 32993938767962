import React from "react";

// Material-ui
import { Theme } from "@mui/material/styles/createTheme";
import { createStyles, makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

// Components
import { Products } from "../components/Products";
import { FragmentType, getFragmentData } from "../../../gql";
import { markingProductFragment, markableFragment } from "../pages/ProductList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    block: {
      paddingBottom: theme.spacing(3),
    },
  })
);

interface IGroupProduct {
  productGroup: string;
  markingProducts: FragmentType<typeof markingProductFragment>[];
}

export const ProductsByGroup = (props: {
  productsByGroups: IGroupProduct[];
  groups: FragmentType<typeof markableFragment>[];
}) => {
  const { productsByGroups, groups } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      {productsByGroups.map((groupProducts: IGroupProduct, index: number) => {
        const { markingProducts, productGroup } = groupProducts;

        return (
          <div key={index} className={classes.block}>
            <Typography variant="h4" gutterBottom>
              {groupProducts.productGroup}
            </Typography>

            <Products
              markingProducts={markingProducts}
              markable={groups.find((obj) => {
                const markable = getFragmentData(markableFragment, obj);
                return markable.name === productGroup;
              })}
            />
          </div>
        );
      })}
    </>
  );
};
